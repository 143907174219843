import React from "react"
import { FaEnvelope, FaPhone, FaTwitter } from "react-icons/fa"

export const contactLinks = [
  {
    icon: <FaEnvelope />,
    text: `info@outliertechnology.co.uk`,
    link: `info@outliertechnology.co.uk`,
  },
  {
    icon: <FaPhone />,
    text: `+44(0)20 3858 9966`,
    link: `+44(0)20 3858 9966`,
  },
  {
    icon: <FaTwitter />,
    text: `@outliertechnol`,
    link: `https://twitter.com/outliertechnol`,
  },
]
