import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./logo.scss"

const Logo = () => {
  const data = useStaticQuery(
    graphql`
      fragment ChildImgSharpInfo on File {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      query {
        logo: file(relativePath: { regex: "/outl.*-logo/" }) {
          ...ChildImgSharpInfo
        }
      }
    `
  )

  return (
    <div className="outlierLogo">
      <Img fluid={data.logo.childImageSharp.fluid} />
    </div>
  )
}

export default Logo
