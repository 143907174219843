import React from "react"
import Logo from "../components/logo"

export const pageSections = [
  {
    text: <Logo />,
    className: `logo`,
    link: `#home`,
    scrollIndex: 0,
  },
  {
    text: `Home`,
    link: `#home`,
    scrollIndex: 0,
  },
  {
    text: `What we do`,
    link: `#what-we-do`,
    scrollIndex: 4,
  },
  {
    text: `Get in touch`,
    link: `#get-in-touch`,
    scrollIndex: 8,
  },
]
