import React, {useState} from "react"
import {useStaticQuery, graphql} from "gatsby"
import ReactPageScroller from "react-page-scroller"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Section from "../components/section"

import r1 from '../images/index-page/home-00-fg.svg'
import r2 from '../images/index-page/home-01-fg.svg'
import r3 from '../images/index-page/home-02-fg.svg'
import r4 from '../images/index-page/home-03-fg.svg'
import r5 from '../images/index-page/we-do-00-fg.svg'
import r6 from '../images/index-page/we-do-01-fg.svg'
import r7 from '../images/index-page/we-do-02-fg.svg'
import r8 from '../images/index-page/we-do-03-fg.svg'
import rLast from '../images/index-page/get-in-touch-00-fg.svg'

import l1 from '../images/index-page/home-00-bg.svg'
import l2 from '../images/index-page/home-01-bg.svg'
import l3 from '../images/index-page/home-02-bg.svg'
import l4 from '../images/index-page/home-03-bg.svg'
import l5 from '../images/index-page/we-do-00-bg.svg'
import l6 from '../images/index-page/we-do-01-bg.svg'
import l7 from '../images/index-page/we-do-02-bg.svg'
import l8 from '../images/index-page/we-do-03-bg.svg'
import lLast from '../images/index-page/get-in-touch-00-bg.svg'

import {siteData} from "../data/site-data"
import {contactLinks} from "../data/contact-links"

import "./index.scss"
import Section2 from "../components/section2";
import Section3 from "../components/section3";

const TRANSITION_TIMER = 10

const IndexPage = () => {


    const [currentSlideIndex, setCurrentSlideIndex] = useState(1)

    let scrollerRef = null
    const setScrollerRef = r => (scrollerRef = r)
    const pageOnChange = index => {

            setTimeout(() => {
                setCurrentSlideIndex(index)
            }, TRANSITION_TIMER * 1.1)

    }

    const goToPage = pageNumber => {
        console.log('goto page');
        scrollerRef.goToPage(pageNumber)
    }

    return (
        <Layout>
            <SEO title={siteData.title}/>
            <Header goToPage={goToPage}/>
            <ReactPageScroller
                renderAllPagesOnFirstRender={true}
                ref={setScrollerRef}
                animationTimer={TRANSITION_TIMER * 0.75}
                pageOnChange={pageOnChange}
                transitionTimingFunction={'ease-in-out'}
                customPageNumber={currentSlideIndex}
            >
                {/* home 00 */}
                <Section2
                    bgImage={l1}
                    bgImageAnimation={"fadeInRightBig"}
                    bgImagePosition={[`0`, `auto`, `0`, `0`]}
                    fgImage={r1}
                    fgImageAnimation={"fadeInLeftBig"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`0`, `auto`, `auto`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={1}
                />
                {/* home 01 */}
                <Section2
                    text={getTextToBeTyped([
                        `In this digital world, competitors are`,
                        `working hard to snap up your customers.`,
                        `You have to market to <b>100,000</b> people,`,
                        `for <b>500</b> to click your ad, <b>50</b> your`,
                        `website, <b>10</b> your email just to get one`,
                        `customer. You are confused by tech. Your`,
                        `costly databases don’t deliver.`,
                        `You feel left behind.`,
                    ])}
                    textAnimation={"fadeInDown"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l2}
                    bgImageAnimation={"rotateInDownLeft"}
                    bgImagePosition={[`0`, `auto`, `0`, `0`]}
                    fgImage={r2}
                    fgImageAnimation={"rotateInDownRight"}
                    fgImagePosition={[`5%`, `auto`, `0`, `0`]}
                    impl={[`0`, `auto`, `auto`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={2}
                />
                {/* home 02 */}


                <Section2
                    text={getTextToBeTyped([
                        `We are <b>outliers</b>. We do data differently.`,
                        `We harness it to identify more leads, win`,
                        `you more business, satisfy your`,
                        `customers, and drive up profits.`,
                        `<br>`,
                        `Others focus on the cool tools – we on`,
                        `your commercial <b>outcomes</b>. We`,
                        `understand what keeps you up at night.`,
                        `We ask the right questions to sort not just`,
                        `the needles but the millions of haystacks,`,
                        `too. We make your pennies count. We use`,
                        `tech to deliver concrete results.`,
                    ])}
                    textAnimation={"rollIn"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l3}
                    bgImageAnimation={"jackInTheBox"}
                    bgImagePosition={[`0`, `10%`, `0`, `0`]}
                    fgImage={r3}
                    fgImageAnimation={"fadeInDownBig"}
                    fgImagePosition={[`5%`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={3}
                />
                <Section2
                    text={getTextToBeTyped([
                        `You can trust us. We speak your`,
                        ` language. We\'re confident enough to`,
                        ` deliver cutting edge or tried and tested.`,
                        ` We find the right solution for you, even`,
                        ` if it means we charge you less`
                    ])}
                    textAnimation={"rollIn"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l3}
                    bgImageAnimation={'noAnim'}
                    bgImagePosition={[`0`, `10%`, `0`, `0`]}
                    fgImage={r3}
                    fgImageAnimation={'noAnim'}
                    fgImagePosition={[`5%`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={4}
                />
                <Section2
                    text={getTextToBeTyped([
                        `We are <b>innovative</b> - it's in our name.`,
                        `We figure out what makes your`,
                        `customers tick, and how to win and keep`,
                        `them. We help you identify new business`,
                        `before your competitors grab it`,
                        `<br>`,
                        `Others sell a fantasy. We are <b>honest</b>`,
                        `about what can and can't be done. We`,
                        `might tell you something you don't want`,
                        `to hear, but it will be the truth`

                    ])}
                    textAnimation={"rollIn"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l3}
                    bgImageAnimation={"noAnim"}
                    bgImagePosition={[`0`, `10%`, `0`, `0`]}
                    fgImage={r3}
                    fgImageAnimation={"noAnim"}
                    fgImagePosition={[`5%`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={5}
                />
                {/* home 03 */}
                <Section2
                    text={getTextToBeTyped([
                        `We have 20+years <b>experience</b> working`,
                        `on data projects for startups, SMEs, FTSE`,
                        `100 companies and multinationals in`,
                        `finance, data, insurance, energy, retail, and`,
                        `many other sectors, and can offer pure`,
                        `consultancy as well as design and build`,
                        `packages.`,
                        `<br>`,
                        `Our expertise lies in using data to win new`,
                        `business and boost your <b><u>GROWTH</u></b>,`,
                        `and enhance <b><u>PRODUCTIVITY</u></b>.`,
                        `See what <b><u>WE CAN DO FOR YOU.</u></b>`,
                    ])}
                    textAnimation={"rotateInDownLeft"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l4}
                    bgImageAnimation={"rotateInUpLeft"}
                    bgImagePosition={[`0`, `2%`, `0`, `0`]}
                    fgImage={r4}
                    fgImageAnimation={"rotateInDownRight"}
                    fgImagePosition={[`10%`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={6}
                />
                {/* we do 00 */}
                <Section2
                    text={getTextToBeTyped([
                        `<b>We harness data</b>`,
                        `<b>to answer the two</b>`,
                        `<b>questions that keep our</b>`,
                        `<b>customers up at night...</b>`,
                    ])}
                    textAnimation={"size-2x rotateInUpRight"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l5}
                    bgImageAnimation={"rotateInDownRight"}
                    bgImagePosition={[`0`, `5%`, `0`, `0`]}
                    fgImage={r5}
                    fgImageAnimation={"fadeInRightBig"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={7}
                />
                {/* we do 01 */}
                <Section2
                    text={getTextToBeTyped([
                        `<b class="size-2x">Where is my next sale</b>`,
                        `<b class="size-2x">coming from?</b>`,
                        `<br>`,
                        `We boost <b><u>GROWTH</u></b> by analysing customer`,
                        `habits and market behaviour, and figuring`,
                        `out who is most likely to buy from you.`,
                    ])}
                    textAnimation={"rollIn"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l6}
                    bgImageAnimation={"fadeIn"}
                    bgImagePosition={[`0`, `5%`, `0`, `0`]}
                    fgImage={r6}
                    fgImageAnimation={"rotateInDownRight"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={8}
                />

                <Section3
                    text={getTextToBeTyped([

                        `We identify the customer that need`,
                        `attention before they have leave and the`,
                        `lead that need an incentive to convert.`,
                    ])}
                    textAnimation={"rollIn"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l6}
                    bgImageAnimation={"noAnim"}
                    bgImagePosition={[`0`, `5%`, `0`, `0`]}
                    fgImage={r6}
                    fgImageAnimation={"noAnim"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={9}
                />
                {/* we do 02 */}
                <Section2
                    text={getTextToBeTyped([
                        `<b class="size-2x">How can we be</b>`,
                        `<b class="size-2x">more productive?</b>`,
                        `<br>`,
                        `We increase <b>PRODUCTIVITY</b> by`,
                        `forensically analysing your business to`,
                        `help reduce day-to-day costs, manage`,
                        `long term <b>risk</b>, enable better decision`,
                        `making, streamline <b>operations</b> and`,
                        `<b>increase</b> profits.`,
                    ])}
                    textAnimation={"rotateInUpRight"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l7}
                    bgImageAnimation={"rotateInUpRight"}
                    bgImagePosition={[`0`, `5%`, `0`, `0`]}
                    fgImage={r7}
                    fgImageAnimation={"rollIn"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`0`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={10}
                />
                {/* we do 03 */}
                <Section2
                    text={getTextToBeTyped([
                        `Outlier build long term <b>relationships</b>`,
                        `and provide you with the <b>support</b> you`,
                        `need to <b>deliver</b> key projects, <b>train</b>`,
                        `your teams or develop your <b>strategy</b>.`,
                    ])}
                    textAnimation={"fadeInUp"}
                    textPosition={[`8%`, `22%`, `auto`, `auto`]}
                    bgImage={l8}
                    bgImageAnimation={"bounceIn"}
                    bgImagePosition={[`0`, `5%`, `0`, `0`]}
                    fgImage={r8}
                    fgImageAnimation={"rotateIn"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={11}
                />
                {/* get in touch */}
                <Section2
                    text={
                        <div className="contactDetails">
                            {contactLinks.map(item => {
                                return (
                                    <div key={item.text} className="contactItem">
                                        <i>{item.icon}</i>
                                        <a href={item.link}>{item.text}</a>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    textAnimation={"fadeInUp"}
                    bgImage={lLast}
                    bgImageAnimation={"fadeInDown"}
                    bgImagePosition={[`10%`, `20%`, `0`, `-150px`]}
                    fgImage={rLast}
                    fgImageAnimation={"bounceIn"}
                    fgImagePosition={[`0`, `auto`, `0`, `0`]}
                    impl={[`auto`, `auto`, `0`, `0`]}
                    impr={[`auto`, `auto`, `0`, `0`]}
                    currentSlideIndex={currentSlideIndex}
                    slideIndex={12}
                />
            </ReactPageScroller>
        </Layout>
    )
}

export default IndexPage

function getTextToBeTyped(textItems) {
    if (!textItems || !textItems.length) {
        return null
    }

    return textItems.map(item => {
        return (
            <div
                key={JSON.stringify(item)}
                dangerouslySetInnerHTML={{__html: item}}
            ></div>
        )
    })
}
