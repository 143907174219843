import PropTypes from "prop-types"
import React from "react"
import { pageSections } from "../data/page-sections"

import "./header.scss"

const Header = ({ goToPage }) => {
  return (
    <header className="headerNav">
      <ul>
        {pageSections.map(({ text, className = ``, scrollIndex }) => {
          return (
            <li
              key={text}
              className={className}
              onClick={() => goToPage(scrollIndex)}
            >
              {text}
            </li>
          )
        })}
      </ul>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

// < li > </li >
