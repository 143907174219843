import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import Img from "gatsby-image"

import "./section.scss"
import Image from "./image";
import ReactPageScroller from "react-page-scroller";
import Layout from "./layout";

const Section3 = props => {
    const {
        text,
        textAnimation,
        textPosition,
        bgImage,
        bgImageAnimation,
        bgImagePosition,
        fgImage,
        fgImageAnimation,
        fgImagePosition,
        currentSlideIndex,
        slideIndex,
        impl,
        impr
    } = props

    const getPositionStyles = posArr => {
        if (!posArr) {
            return {}
        }
        const [left, top, right, bottom] = posArr
        return {
            position: `absolute`,
            left: `${left}`,
            top: `${top}`,
            right: `${right}`,
            bottom: `${bottom}`,
        }
    }



    const isCurrent = currentSlideIndex === slideIndex
    const textCls = classnames("text", "animated", {
        [`${textAnimation}`]: isCurrent && textAnimation,
    })
    const textCls2 = classnames("text", "animated", {
        ["noAnim"]: isCurrent && textAnimation,
    })
    const bgCls = classnames("imgHolder", "animated", {
        [`${bgImageAnimation}`]: isCurrent && bgImageAnimation,
    })
    const fgCls = classnames("imgHolder", "animated", {
        [`${fgImageAnimation}`]: isCurrent && fgImageAnimation,
    })
    console.log(fgImage);
    if (!bgImage || !fgImage) {
        return null
    }

    return (
        <article className="pageSection">

            {text && (
                <div>

                    <div style={getPositionStyles(textPosition)}>
                        <div className={textCls2}>
                            <b className="size-2x">Where is my next sale</b><br/>
                            <b className="size-2x">coming from?</b>
                        </div>
                        <div className={textCls}>
                            {text}
                        </div>
                    </div>
                </div>
            )}
            {(
                <div className={bgCls} style={getPositionStyles(bgImagePosition)}>
                    <div  className=" gatsby-image-wrapper">
                        <img  style={getPositionStyles(impl)} className="lftImg" src={bgImage}/>
                    </div>
                </div>
            )}
            {(
                <div className={fgCls} style={getPositionStyles(fgImagePosition)}>
                    {/*<Img fluid={fgImage.childImageSharp.fluid} />*/}
                    <div className=" gatsby-image-wrapper">
                        <img  style={getPositionStyles(impr)} className="imgg" src={fgImage}/>
                    </div>
                </div>
            )}
        </article>
    )
}

Section3.propTypes = {
    text: PropTypes.node,
    textAnimation: PropTypes.string,
    textPosition: PropTypes.array,
    bgImage: PropTypes.object,
    bgImageAnimation: PropTypes.string,
    bgImagePosition: PropTypes.array,
    fgImage: PropTypes.object,
    fgImageAnimation: PropTypes.string,
    fgImagePosition: PropTypes.array,
    currentSlideIndex: PropTypes.number,
    slideIndex: PropTypes.number,
}

export default Section3
